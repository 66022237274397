import { useRef } from 'react'
import { Box, Flex } from 'components/primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import ThemeSwitcher from './ThemeSwitcher'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'
import { Dropdown, DropdownMenuItem } from 'components/primitives/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress, faCashRegister, faChartLine, faChevronDown, faDroplet, faStar } from '@fortawesome/free-solid-svg-icons'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const { theme } = useTheme()
  const { isConnected } = useAccount()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()

  let searchRef = useRef<HTMLInputElement>(null)

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (!isMounted) {
    return null
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image
                src="/artopia.png"
                width={34}
                height={34}
                alt="Artopia"
              />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        <MobileSearch key={`${router.asPath}-search`} />
        <CartButton />
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        '@xl': {
          px: '$6',
        },
        width: '100%',
        // maxWidth: 1920,
        mx: 'auto',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '#000000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box
        css={{
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
      >
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ cursor: 'pointer' }}>
              <Image
                src="/artopia.png"
                width={34}
                height={34}
                alt="Artopia"
              />
            </Box>
          </Link>
          <Flex
            align="center"
            css={{
              gap: '$5',
              ml: '$5',
            }}
          >
            
            <Link href={`https://artopia.dev/${routePrefix}`}>
              <NavItem>Back to Artopia</NavItem>
            </Link>
            <Flex
            as={Link}
            href={`/${routePrefix}/`}
            align="center"
            css={{
              height: 44,
              px: 24,
              borderRadius: 8,
              '&:hover': {
                backgroundColor: '$pinkA5'
              }
            }}
          >
            <NavItem>Home</NavItem>
          </Flex>
          <Flex
            as={Link}
            href={`https://artopia.dev/${routePrefix}/collection-rankings`}
            align="center"
            css={{
              height: 44,
              px: 24,
              borderRadius: 8,
              '&:hover': {
                backgroundColor: '$pinkA5'
              }
            }}
          >
            <NavItem>Collections</NavItem>
          </Flex>

               {/* <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <Link href={`/${routePrefix}/collection-rankings`}>
                  <NavItem
                    active={router.pathname.includes('collection-rankings')}
                  >
                    NFTs
                  </NavItem>
                </Link>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240 }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/${routePrefix}/collection-rankings`}>
                      <NavItem
                        active={router.pathname.includes('collection-rankings')}
                      >
                        Trending Collections
                      </NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/collection-rankings`}>
                      <NavItem
                        active={router.pathname.includes('collection-rankings')}
                      >
                        Trending Mints
                      </NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root> */}

            {false && (
              <Link href={`/${routePrefix}/collections/minting`}>
                <NavItem>Mints</NavItem>
              </Link>
            )}
            {false && (
              <Link href="/swap">
                <NavItem>Tokens</NavItem>
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box css={{ flex: 1, px: '$5' }}>
        <GlobalSearch
          ref={searchRef}
          placeholder="Search collections and addresses"
          containerCss={{ width: '100%' }}
          key={router.asPath}
        />
      </Box>

      <Flex
        css={{
          gap: '$3',
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
        justify="end"
        align="center"
      >
         <Flex css={{ gap: '$2', mr: 12 }}>
        <Dropdown
            modal={false}
            trigger={
              <NavItem>
                <Flex as="span" align="center">
             
       
                  {`📦 Resources`}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width={16}
                    height={16}
                    style={{
                      marginLeft: 5,
                      display: 'inline-block'
                    }}
                  />
                </Flex>
              </NavItem>
            }
            contentProps={{
              asChild: true,
              forceMount: true,
              sideOffset: 35
            }}
          >
            <DropdownMenuItem
              as={Link}
              href="https://artopia.dev/portfolio?tab=activity"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChartLine} width={20} height={20}/>
             Activity
            </DropdownMenuItem>
            <DropdownMenuItem
              as={Link}
              href="https://artopia.dev/portfolio?tab=listings"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCashRegister} width={20} height={20}/>
              Listings
            </DropdownMenuItem>
            <DropdownMenuItem
              as={Link}
              href="https://pro.artopia.dev"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              
             ⚡ Artopia Pro
            </DropdownMenuItem>
            <DropdownMenuItem
              as={Link}
              href="https://artopia.dev/portfolio?tab=items"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faDroplet} width={20} height={20}/>
              {`My NFTs`}
    
            </DropdownMenuItem>
            <DropdownMenuItem
              as={Link}
              href="https://artopia.dev/portfolio/settings"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faBarsProgress} width={20} height={20}/>
              {`Settings`}
              
            </DropdownMenuItem>
            </Dropdown>
          <Dropdown
            modal={false}
            trigger={
              <NavItem>
                <Flex as="span" align="center">
             
       
                  {`Rewards`}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width={16}
                    height={16}
                    style={{
                      marginLeft: 5,
                      display: 'inline-block'
                    }}
                  />
                </Flex>
              </NavItem>
            }
            contentProps={{
              asChild: true,
              forceMount: true,
              sideOffset: 35
            }}
          >
            <DropdownMenuItem
              as={Link}
              href="https://artopia.dev/leaderboard"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10
              }}
            >
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faStar} width={20} height={20}/>
              🥇 Points
            </DropdownMenuItem>
            </Dropdown>
                </Flex>

        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
        <CartButton />
      </Flex>
    </Flex>
  )
}

export default Navbar
